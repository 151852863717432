

export function datetimePipe(dateText) {
    if (!dateText) return '-';
    const date = new Date(dateText);
    return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${date.getHours()}시 ${date.getMinutes()}분`;
}

export function genderPipe(gender) {
    switch (gender) {
        case 'man': return '남자';
        case 'woman': return '여자';
        default: return '-';
    }
}

export function fileNameFromPathPipe(filePath) {
    if (!filePath) return '';
    const start = filePath.lastIndexOf("/");
    if (start < 0) return '';
    return filePath.slice(start + 1);
}

export function mobilePipe(mobile) {
    if (!mobile) return '-';
    if (mobile.length === 11) return `${mobile.slice(0, 3)}-${mobile.slice(3, 7)}-${mobile.slice(7, 11)}`;
    return mobile;
}

export function numberFormatPipe(x) {
    if (!x) return x;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function order_state_pipe(state) {
    switch (state) {
        case 'pending': return '입금대기';
        case 'done': return '주문완료';
        default: return '-';
    }
}

export function itemCategoryPipe(category) {
    switch (category) {
        case 'golf': return '골프화';
        case 'height': return '키높이';
        case 'sneakers': return '운동화';
        case 'accessory': return 'ACCESSORIES';
        case 'nischeom': return 'NISCHEOM';
        default: return '-';
    }
}

export function bucketStatePipe(bucket) {
    if (!bucket?.state) return '-';
    switch(bucket.state) {
        case 'waiting': return '확인대기';
        case 'ready': return '발송준비';
        case 'sended': return '배송중';
        case 'received': return '배송완료';
        case 'done': return '구매완료';
        case 'canceled': return '취소됨';
        case 'returned': return '반품됨';
        default: return '-';
    }
}

export function stuffOptionPipe(stuff) {
    if (!stuff?.proxy) return '-';
    return proxyOptionPipe(stuff.proxy);
}

export function proxyOptionPipe(proxy) {
    if (!proxy?.proxyOptions) return '-';

    let result = [];
    for (const proxyOption of proxy.proxyOptions) {
        result.push(proxyOption.option.name);
    }
    return result.join(',');
}

export function destinationAddressPipe(destination) {
    if (!destination) return '-';
    return `[${destination.zipcode}] ${destination.address} ${destination.addressDetail}`
}

export function inquiryStatePipe(state) {
    switch(state) {
        case 'reported': return '문의중';
        case 'replied': return '답변완료';
        default: return '-';
    }
}

export function reviewCategoryPipe(category) {
    switch(category) {
        case 'bucket': return '버킷';
        default: return '-';
    }
}

export function claimStatePipe(claim) {
    if (claim.division === 'return') {
        switch (claim.state) {
            case 'reported': return '반품요청';
            case 'rejected': return '거절처리됨';
            case 'retrieving': return '수거진행중';
            case 'done': return '반품완료';
            default: return '-';
        }
    }
    else {
        switch (claim.state) {
            case 'reported': return '교환요청';
            case 'rejected': return '거절처리됨';
            case 'retrieving': return '수거진행중';
            case 'resending': return '재배송준비중';
            case 'done': return '교환완료';
            default: return '-';
        }
    }
}

export function alarmCodeStatePipe(code) {
    switch(code) {
        case 'directly': return '직접알림';
        case 'claim_rejected': return '클레임:거절';
        case 'claim_retrieving': return '클레임:수거진행중';
        case 'claim_retrieved': return '클레임:수거완료';
        case 'bucket_canceled': return '버킷:취소완료';
        case 'bucket_created': return '버킷:생성';
        case 'day15': return 'day15 쿠폰이 지급되었음을 알림';
        default: return '-';
    }
}

export function user_pipe(user) {
    return user? `${user.nickname} (${user?.id})` : '비로그인';
}

export function user_state_pipe(state) {
    switch (state) {
        case 'normal': return '정상';
        case 'pending': return '인증대기';
        case 'stopped': return '정지';
        case 'leaved': return '탈퇴';
        default: return '-';
    }
}

export function authsPipe(auths) {
    if (!auths) return '';
    const result = [];
    auths.forEach(auth => {
        switch (auth.code) {
            case 'membership': result.push('멤버십'); break;
            case 'app_admin': result.push('관리자'); break;
            default: break;
        }
    })
    return result.join(',');
}

export function discountPipe(discount) {
    if (!discount) return '';

    const { amount, rate } = discount;
    
    if (amount && rate) {
        return `${amount}원 (${rate}%)`;
    }
    else if (amount) {
        return `${amount}원`;
    }
    else if (rate) {
        return `${rate}%`;
    }
    else {
        return '-';
    }
}

export function logDivisionPipe(division) {
    if (division === 'error') return '에러';
    else if (division === 'info') return '정보';
    else return '-';
}

export function platform_pipe(platform) {
    if (platform === 'coupang') return '쿠팡';
    else if(platform === 'naver') return '네이버';
    else return '-';
}

export function coupon_code_pipe(code) {
    if (code == 'day15') return 'Day15';
    else if (code === 'manager_generate') return '매니저 발급';
    else if (code === 'with_business') return '협력업체';
    else if (code === 'thankyou') return '땡큐 쿠폰';
    return '-';
}