import React, { useContext, useState } from 'react';
import { Button, MenuItem, Paper, TextField, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import useSearchCoupon from 'api/coupon/useSearchCoupon';
import CouponTable from './CouponTable';
import { SelectedProvider, useSelected } from 'contexts/selected.context';
import API from 'api';
import { AppContext } from 'contexts/app.context';


const CouponListPage = () => {
    const theme = useTheme();
    const history = useHistory();
    const {showSnackbar} = useContext(AppContext);

    const [ code, setCode ] = useState('');
    const [ page, setPage ] = useState(0);
    const [ trigger, setTrigger ] = useState();
    
    const { loading, error, rows, mayMore, totalCount, rowsInPage } = useSearchCoupon(code, page, trigger);

    const selected = useSelected();

    const handleRefresh = () => {
        setTrigger(new Date().getTime());
    }

    const handleRemove = () => {
        const promisis = selected.map(id => {
            return API.delete(`/coupon/${id}`)
        })

        Promise.all(promisis)
        .then(() => {
            showSnackbar('삭제했습니다.');
            handleRefresh();
        });
    }

    const handlePrint = () => {
        const ids = selected.join(',');
        history.push('/coupon/print?ids=' + ids);
    }

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>쿠폰내역</PageTitle>

                {!loading ? <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            select
                            style={{ minWidth: '120px' }}
                            size='small'
                            variant="outlined"
                            label={'구분'}
                            onChange={(event) => { setCode(event.target.value); }}
                            value={code}
                        >
                            <MenuItem value=''>선택안함</MenuItem>
                            <MenuItem value={'with_business'}>협력업체</MenuItem>
                            <MenuItem value={'manager_generate'}>매니저 발급</MenuItem>
                            <MenuItem value={'day15'}>MNX15 데이</MenuItem>
                        </TextField>
                        
                        <Button style={{ marginLeft: 'auto', marginRight: 8 }} variant="contained" color="secondary" disabled={selected.length === 0} onClick={handleRemove}>선택 삭제</Button>
                        <Button style={{ marginRight: 8 }} variant="contained" color="primary" disabled={selected.length === 0} onClick={handlePrint}>선택 인쇄</Button>
                        <Button variant="contained" color="primary" onClick={handleRefresh}>새로고침</Button>
                    </div>
                    <div style={{ marginTop: theme.spacing(1) }}>
                        <CouponTable
                            loading={loading}
                            rows={rows} 
                            totalCount={totalCount} 
                            rowsInPage={rowsInPage} 
                            page={page} 
                            setPage={setPage} 
                        />
                    </div>
                </> : <Loading />}
                {error && <div>{error}</div>}
            </Paper>
        </div>
	);
}

export default function() {
    return (
        <SelectedProvider selected={[]}>
            <CouponListPage />
        </SelectedProvider>
    )
}