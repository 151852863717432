import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Button, Paper } from '@material-ui/core';
import Nodata from 'components/Nodata';
import PageTitle from 'components/PageTitle';
import QRCode from 'react-qr-code';
import useQuery from 'hooks/useQuery';
import API from 'api';
import { useReactToPrint } from 'react-to-print';



const useStyles = makeStyles((theme) => ({
    card: {
        margin: '20px',
        border: '1px solid #bdbdbd',
        width: '360px',
        height: '180px',
        padding: '8px'
    }
}));

const CouponPrintPage = () => {
	const classes = useStyles();
    const query = useQuery();
    const ids = query.get('ids');
    

    const [coupons, setCoupons] = useState(null);
    useEffect(() => {
        const ids = query.get('ids');
        API.get('/coupon/get-printable-coupons?ids=' + ids)
        .then(setCoupons)
    }, [ids]);

    const printRef = useRef();
    const onPrintClick = useReactToPrint({
        contentRef: printRef
    })

    if (!coupons) return null;

	return (
        <div className="pageMargin">
            <Paper className="pagePadding">
                <PageTitle>쿠폰 인쇄</PageTitle>

                <div style={{ paddingLeft: '20px' }}>
                    <Button color='primary' variant='contained' onClick={onPrintClick}>인쇄</Button>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap' }} ref={printRef}>
                    {coupons.map(coupon => <div key={coupon.id} className={classes.card}>
                        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <img src={`${process.env.REACT_APP_HOST}/images/logo_black_219_55.png`} style={{ width: 219 / 3, height: 55 / 3}} />
                                {/* <div style={{ fontSize: 14, fontWeight: 'bold' }}>MNX15</div> */}
                                <div style={{ fontSize: 12, color: '#888' }}>{coupon.uuid}</div>
                            </div>

                            <div style={{ marginTop: 16, fontSize: 13, color: '#424242' }}>QR 코드를 카메라로 촬영하시면 할인된 가격에 제품을 구매할 수 있습니다. </div>

                            <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
                                <div style={{ flex: 1 }}>
                                    <div style={{ fontSize: 13, color: '#555' }}>쿠폰할인: {coupon.discountRate}%</div>
                                    <div style={{ fontSize: 13, color: '#555' }}>{'유효기간: '}{coupon.startDate ? `${coupon.startDate}~${coupon.endDate}` : `${coupon.endDate}까지`}</div>
                                    <div style={{ fontSize: 13, color: '#555', marginTop: 8 }}>{coupon.businessMemo}</div>
                                </div>


                                <div style={{ marginLeft: 5 }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: '80px', width: "80px" }}
                                        value={coupon.url}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>

                {coupons.length === 0 && <Nodata>쿠폰이 없습니다.</Nodata>}
            </Paper>
        </div>
	);
}

export default CouponPrintPage;