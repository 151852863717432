import React, { useContext, useEffect, useMemo, useState } from 'react';
import { List, ListItem, ListItemText, makeStyles, Collapse, ListSubheader, Badge } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import colors from '../appcolors';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MemoryContext } from 'contexts/memory.context';


// link 가 false 이면 link 가 안됨
const userMenus = [
    { 
        route: '/user',
        link: true,
        name: '사용자',
        children: [],
    },
    { 
        route: '/cafeuser',
        link: true,
        name: '카페24 사용자',
        children: [],
    },
    { 
        route: '/ticket',
        link: true,
        name: '1:1 문의',
        children: [],
    },
    { 
        route: '/inquiry',
        link: true,
        name: '이메일 문의',
        children: [],
    },
];

const productMenus = [
    { 
        route: '/item',
        link: false,
        name: '제품',
        children: [
            {
                route: '/item/list',
                link: true,
                name: '제품'
            },
            {
                route: '/item/register',
                link: true,
                name: '제품등록',
            },
        ]
    },
];

const orderMenus = [
    {
        route: '/order/list',
        link: true,
        name: '주문내역 - 전자결제',
    },
    {
        route: '/order/transfer-list',
        link: true,
        name: '주문내역 - 계좌이체',
    },
];

const BucketMenus = [
    {
        route: '/bucket/waiting',
        link: true,
        name: '확인대기'
    },
    {
        route: '/bucket/ready',
        link: true,
        name: '발송준비'
    },
    {
        route: '/bucket/sended',
        link: true,
        name: '배송중',
    },
    {
        route: '/bucket/received',
        link: true,
        name: '배송완료'
    },
    {
        route: '/bucket/done',
        link: true,
        name: '구매완료'
    },
    {
        route: '/bucket/canceled',
        link: true,
        name: '취소완료'
    },
    {
        route: '/bucket/returned',
        link: true,
        name: '반품완료'
    },
    {
        route: '/bucket/generate',
        link: true,
        name: '버킷생성'
    },
];

const ClaimMenus = [
    {
        route: '/claim/return',
        link: true,
        name: '반품요청'
    },
    {
        route: '/claim/replace',
        link: true,
        name: '교환요청',
    },
];

const ReviewMenus = [
    {
        route: '/review/list',
        link: true,
        name: '리뷰관리'
    },
];

const CouponMenus = [
    {
        route: '/coupon/generate',
        link: true,
        name: '쿠폰발행'
    },
    {
        route: '/coupon/list',
        link: true,
        name: '쿠폰관리'
    },
];

const AlarmMenus = [
    {
        route: '/alarm/send',
        link: true,
        name: '보내기'
    },
    {
        route: '/alarm/list',
        link: true,
        name: '알림내역'
    },
    { 
        route: '/sms',
        link: true,
        name: 'SMS',
    },
];

const StatisticsMenus = [
    {
        route: '/statistics/dashboard',
        link: true,
        name: '계기판'
    },
    {
        route: '/statistics/log',
        link: true,
        name: '로그'
    },
    {
        route: '/statistics/notify-email',
        link: true,
        name: '쿠폰 이메일 알림'
    },
    {
        route: '/statistics/referrer',
        link: true,
        name: '링크된 주소'
    },
    {
        route: '/statistics/host-referrer',
        link: true,
        name: '링크된 호스트'
    },
];

const ColumnMenus = [
    {
        route: '/column/list',
        link: true,
        name: '칼럼'
    },
    {
        route: '/column/write',
        link: true,
        name: '칼럼등록',
    },
];

const SettingsMenus = [
    {
        route: '/settings',
        link: true,
        name: '설정'
    },
];

const testMenus = [
    {
        route: '/development',
        link: false,
        name: 'Test',
        children: [
            {
                route: '/development/typography',
                link: true,
                name: 'Typography',
            },
            {
                route: '/development/color',
                link: true,
                name: 'Color',
            },
            {
                route: '/development/button',
                link: true,
                name: 'Button',
            },
            {
                route: '/development/table',
                link: true,
                name: 'Table',
            },
            {
                route: '/development/input',
                link: true,
                name: 'Input',
            },
            {
                route: '/development/enhanced-table',
                link: true,
                name: 'Enhanced Table',
            },
            {
                route: '/development/key-value',
                link: true,
                name: 'Key Value',
            },
            {
                route: '/development/modal',
                link: true,
                name: 'Modal',
            },
            {
                route: '/development/guide',
                link: true,
                name: 'Guide',
            },
        ]
    },
    {
        route: '/development',
        link: false,
        name: 'API',
        children: [
            {
                route: '/development/foo',
                link: true,
                name: 'Foo',
            },
            {
                route: '/development/infinite-scroll',
                link: true,
                name: 'InfiniteScroll',
            },
        ]
    },
];


const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    menuHeader: {
        color: colors.textPrimary,
    },
    nested: {
        paddingLeft: theme.spacing(4), 
        borderRadius: theme.spacing(1),
    },
    menuitem: {
        borderRadius: theme.spacing(1),
    },
    active: {
        backgroundColor: colors.primaryTint,
        color: colors.primaryDark,
    },
    inactive: {
        backgroundColor: 'transparent',
        color: colors.textSecondary,
    },
}));

function SidebarMenus() {
	const classes = useStyles();

	return (
		<div className={classes.container}>
            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>User</ListSubheader>}
                className={classes.root}
            >
                {userMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Claim</ListSubheader>}
                className={classes.root}
            >
                {ClaimMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Order</ListSubheader>}
                className={classes.root}
            >
                {orderMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Bucket</ListSubheader>}
                className={classes.root}
            >
                {BucketMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Product</ListSubheader>}
                className={classes.root}
            >
                {productMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Review</ListSubheader>}
                className={classes.root}
            >
                {ReviewMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Coupon</ListSubheader>}
                className={classes.root}
            >
                {CouponMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Alarm</ListSubheader>}
                className={classes.root}
            >
                {AlarmMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Statistics</ListSubheader>}
                className={classes.root}
            >
                {StatisticsMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Column</ListSubheader>}
                className={classes.root}
            >
                {ColumnMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            <List
                component="nav"
                subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Settings</ListSubheader>}
                className={classes.root}
            >
                {SettingsMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
            </List>

            {process.env.NODE_ENV === 'development' && <>    
                <List
                    component="nav"
                    subheader={<ListSubheader component="div" style={{ color: colors.textPrimary }}>Development</ListSubheader>}
                    className={classes.root}
                >
                    {testMenus.map((parent, index) => <HirachicalMenuItem key={index} menu={parent} />)}
                </List>
            </>}
        </div>
	);
}


const alarmMaps = [
    { key: 'ticketCount', route: '/ticket' },
    { key: 'inquiryCount', route: '/inquiry' },
    { key: 'waitingCount', route: '/bucket/waiting' },
    { key: 'readyCount', route: '/bucket/ready' },
    { key: 'returnCount', route: '/claim/return' },
    { key: 'replaceCount', route: '/claim/replace' },
    { key: 'pendingOrderCount', route: '/order/transfer-list' },
];

const HirachicalMenuItem = ({ menu, isChild }) => {
    const classes = useStyles();
    const history = useHistory();
    
    const { mainInfo } = useContext(MemoryContext);

    const routeMatch = useRouteMatch(menu.route);
    
    const haveChildren = useMemo(() => {
        return menu.children && menu.children.length > 0
    }, []);

    const [ menuOpen, setMenuOpen ] = useState(false);
    useEffect(() => {
        setMenuOpen(!!routeMatch);
    }, [ haveChildren ]);
    // end: dropdown open / close

    // route match exact
    const matchExact = useMemo(() => {
        return routeMatch?.isExact;
    }, [ routeMatch ]);
    // end: route match exact

    const onMenuItemClick = () => {
        if (menu.link) history.push(menu.route);
        if (haveChildren) setMenuOpen(val => !val);
    }

    const badgeCount = useMemo(() => {
        if (!mainInfo) return;
        const element = alarmMaps.find(el => el.route === menu.route);
        if (element) {
            const result = mainInfo[element.key];
            return result;
        }
    }, [ mainInfo ]);

    return (
        <>
            <ListItem
                button
                onClick={onMenuItemClick} 
                className={clsx({ [classes.nested]: isChild, [classes.menuitem]: !isChild, [classes.active]: matchExact, [classes.inactive]: !matchExact })}
            >
                <Badge badgeContent={badgeCount} color="secondary">
                    <ListItemText primary={menu.name} />
                </Badge>
                
                {haveChildren && <>
                    {menuOpen ? <ExpandLess /> : <ExpandMore />}
                </>}
            </ListItem>

            {haveChildren && <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menu.children.map((child, index) => <HirachicalMenuItem key={index} menu={child} isChild={true} />)}
                </List>
            </Collapse>}
        </>
    );
}


export default SidebarMenus;
