import React, { useContext } from 'react';
import { Typography, makeStyles, Button, TextField, Paper, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import colors from 'appcolors';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import { AppContext } from 'contexts/app.context';



const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        marginBottom: '8px',
    },
    input: {
        alignSelf: 'stretch',
    },
}));

const CouponGeneratePage = () => {
	const classes = useStyles();
	const history = useHistory();

    const { showSnackbar, showDialog } = useContext(AppContext);

    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            code: '',
            startDate: '',
            endDate: '',
            discountRate: '20',
            generatorMemo: '',
            businessMemo: '',
        },
        validationSchema: yup.object({
            code: yup.string().required('필수입력입니다.'),
            startDate: yup.date().nullable(),
            endDate: yup.date().required('필수입력입니다.'),
            discountRate: yup.number().required('필수입력입니다.').min(5, '최소 5프로 이상으로 입력해주세요.').max(100, '최대 100프로 이하로 입력해주세요.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (values.code === 'with_business' && !values.businessMemo) return showSnackbar('협력업체명을 입력해주세요.');
            if (!values.startDate) values.startDate = null;

            API.post('/coupon/generate-coupon', values)
            .then(() => {
                const dialogData = {
					title: '쿠폰 발행',
					message: '발행되었습니다.\n목록으로 이동하시겠습니까?',
					first: {
						onClick: () => {
							history.push('/coupon/list');
						}
					},
					second: true,
				}
				showDialog(dialogData);
            })
            .finally(() => { setSubmitting(false) });
        },
    });

	return (
        <div className="pageMargin">
            <Paper className="pagePadding" style={{ maxWidth: '640px' }}>
                <PageTitle>쿠폰 발행</PageTitle>

                <form onSubmit={handleSubmit}>
                    <div className={classes.section}>
                        <Typography variant="subtitle2" className={classes.label}>구분 *</Typography>
                        <TextField
                            select
                            style={{ width: '100%' }}
                            size='small'
                            variant="outlined"
                            label={'구분'}
                            required={true}
                            onChange={handleChange('code')}
                            onBlur={handleBlur('code')}
                            error={!!(touched.code && errors.code)}
                            value={values.code || ''}
                        >
                            <MenuItem value={'with_business'}>협력업체</MenuItem>
                            <MenuItem value={'manager_generate'}>매니저 발급</MenuItem>
                        </TextField>
                    </div>
                    
                    <div className={classes.section}>
                        <Typography variant="subtitle2" className={classes.label}>사용가능 시작일</Typography>
                        <TextField
                            variant="outlined"
                            className={classes.input}
                            size='small'
                            type={'date'}
                            onChange={handleChange('startDate')}
                            onBlur={handleBlur('startDate')}
                            helperText={touched.startDate && errors.startDate || ' '}
                            error={!!(touched.startDate && errors.startDate)}
                            value={values.startDate}
                        />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="subtitle2" className={classes.label}>사용가능 종료일 *</Typography>
                        <TextField
                            variant="outlined"
                            className={classes.input}
                            size='small'
                            required={true}
                            type={'date'}
                            onChange={handleChange('endDate')}
                            onBlur={handleBlur('endDate')}
                            helperText={touched.endDate && errors.endDate || ' '}
                            error={!!(touched.endDate && errors.endDate)}
                            value={values.endDate}
                        />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="subtitle2" className={classes.label}>할인율(%) *</Typography>
                        <TextField
                            variant="outlined"
                            className={classes.input}
                            size='small'
                            required={true}
                            type="number"
                            onChange={handleChange('discountRate')}
                            onBlur={handleBlur('discountRate')}
                            helperText={touched.discountRate && errors.discountRate || ' '}
                            error={!!(touched.discountRate && errors.discountRate)}
                            value={values.discountRate}
                        />
                    </div>

                    <div className={classes.section}>
                        <Typography variant="subtitle2" className={classes.label}>메모</Typography>
                        <TextField
                            multiline
                            minRows={3}
                            variant="outlined"
                            className={classes.input}
                            size='small'
                            onChange={handleChange('generatorMemo')}
                            onBlur={handleBlur('generatorMemo')}
                            helperText={touched.generatorMemo && errors.generatorMemo || ' '}
                            error={!!(touched.generatorMemo && errors.generatorMemo)}
                            value={values.generatorMemo}
                        />
                    </div>
                    
                    {values.code === 'with_business' && <div className={classes.section}>
                        <Typography variant="subtitle2" className={classes.label}>협력업체명 *</Typography>
                        <TextField
                            variant="outlined"
                            className={classes.input}
                            size='small'
                            onChange={handleChange('businessMemo')}
                            onBlur={handleBlur('businessMemo')}
                            helperText={touched.businessMemo && errors.businessMemo || ' '}
                            error={!!(touched.businessMemo && errors.businessMemo)}
                            value={values.businessMemo}
                        />
                    </div>}

                    <Button type="submit" variant="contained" color="primary">발행</Button>
                </form>
            </Paper>
        </div>
	);
}

export default CouponGeneratePage;